.bg-secondary2 {
    --tw-bg-opacity: 1;
    background-color: #065FD3;
}

.bg-secondary3 {
    --tw-bg-opacity: 1;
    background-color: #065FD3;
    color: white;
}

.text-primary2 {
    --tw-text-opacity: 1;
    color: #f5f8f5;
}

.text-primary3 {
    --tw-text-opacity: 1;
    color: #11329e;
}